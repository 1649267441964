
// Analgesics Images 
import Analgesics1 from "../../assests/products/ap-klub-sp-10.JPG"
import Analgesics2 from "../../assests/products/ap-klub.jpg"
import Analgesics3 from "../../assests/products/rohnim-plus.png"
import Analgesics4 from "../../assests/products/ohmenac-plus.png"
import Analgesics5 from "../../assests/products/ap-klub-sp.jpg"

// Gastro Images 
import Gastro1 from "../../assests/products/rohbi-dsr.png"
import Gastro2 from "../../assests/products/rohpan-dsr.png"
import Gastro3 from "../../assests/products/rohbi-ls.png"
import Gastro4 from "../../assests/products/ohme-20.png"
import Gastro5 from "../../assests/products/ohmi-loc.png"

// Syrups 
import Syrups1 from "../../assests/products/ohmeril-ls.png"
import Syrups2 from "../../assests/products/ohmedex.png"
import Syrups3 from "../../assests/products/ohmerex-t.png"

// Anti-Bacterial / Microbail 
import Microbail1 from "../../assests/products/itz-100.png"
import Microbail2 from "../../assests/products/itz-200.png"
import Microbail3 from "../../assests/products/mohnti-lc.png"

// Hematinic & Neutraceuticals
import Hematinic1 from "../../assests/products/ferohm-xt.png"
import Hematinic2 from "../../assests/products/omenerve.png"
import Hematinic3 from "../../assests/products/ferohm-xt-sg.png"
import Hematinic4 from "../../assests/products/ohmenerve-gold.png"
import Hematinic5 from "../../assests/products/nutriklub-sg.png"
import Hematinic6 from "../../assests/products/multiklub-sg.png"
import Hematinic7 from "../../assests/products/i-beta-carotine.png"
import Hematinic8 from "../../assests/products/lycocherry-lg.png"

// Ointment & Derma 
import Ointment1 from "../../assests/products/rohmigel-super.jpg"
import Ointment2 from "../../assests/products/ohmidine.jpg"
import Ointment3 from "../../assests/products/ohmidine-m.jpg"
import Ointment4 from "../../assests/products/neoklub-gm.jpg"
import Ointment5 from "../../assests/products/licegel.jpg"
import Ointment6 from "../../assests/products/ohmsore.jpg"
import Ointment7 from "../../assests/slider_1.png"
import Ointment8 from "../../assests/slider_1.png"

// Paediatric 
import Paediatric1 from "../../assests/products/rohmentin-ds.jpg"
import Paediatric2 from "../../assests/products/rohmentin-dds.jpg"
import Paediatric3 from "../../assests/products/cedox-cv-50.jpg"
import Paediatric4 from "../../assests/products/rohcef-of-50.jpg"
import Paediatric5 from "../../assests/products/rohcef-cv-50.png"
import Paediatric6 from "../../assests/products/p-cemol.png"

// Neuro 
import Neuro1 from "../../assests/products/throma-50.png"
import Neuro2 from "../../assests/slider_1.png"
import Neuro3 from "../../assests/products/prohgesic-75.png"
import Neuro4 from "../../assests/products/prohgesic-m75.png"
import Neuro5 from "../../assests/products/proxiohm-spas.png"
import Neuro6 from "../../assests/products/proxiohm-spas-brown.png"
import Neuro7 from "../../assests/products/proxiohm-spas-144.png"

// Anti Biotics
import Biotics1 from "../../assests/products/rohmentin-625.png"
import Biotics2 from "../../assests/products/rohmentin-aa.png"
import Biotics3 from "../../assests/slider_1.png"
import Biotics4 from "../../assests/products/cedox.png"

// Injectibles 
import Injectibles1 from "../../assests/products/ohmicef-1g.png"
import Injectibles2 from "../../assests/products/ohmicef-sb.png"
import Injectibles3 from "../../assests/products/rohpan-40.png"
import Injectibles4 from "../../assests/products/vomitac.png"


const ProductsData = [
  {
    id: 1,
    image: Analgesics1,
    title: "AP-KLUB SP 10",
    cost: 120.00,
    category: "analgesics",
  },
  {
    id: 2,
    image: Analgesics2,
    title: "AP-KLUB",
    cost: 77.50,
    category: "analgesics",
  },
  {
    id: 3,
    image: Analgesics3,
    title: " ROHNIM PLUS",
    cost: 50.00,
    category: "analgesics",
  },
  {
    id: 4,
    image: Analgesics4,
    title: " OHMENAC-PLUS",
    cost: 36.00,
    category: "analgesics",
  },
  {
    id: 5,
    image: Analgesics5,
    title: " AP-KLUB-SP",
    cost: 125.00,
    category: "analgesics",
  },
  {
    id: 6,
    image: Hematinic1,
    title: " FEROHMXT TAB",
    cost: 175.00,
    category: "hematinic-neutraceuticals",
  },
  {
    id: 7,
    image: Hematinic2,
    title: " OMENERVE TAB",
    cost: 199.00,
    category: "hematinic-neutraceuticals",
  },
  {
    id: 8,
    image: Hematinic3,
    title: " FEROHM XT SG",
    cost: 139.00,
    category: "hematinic-neutraceuticals",
  },
  {
    id: 9,
    image: Hematinic4,
    title: " OMENERVE GOLD SG",
    cost: 199.00,
    category: "hematinic-neutraceuticals",
  },
  {
    id: 10,
    image: Hematinic5,
    title: " NUTRIKLUB SG",
    cost: 199.00,
    category: "hematinic-neutraceuticals",
  },
  {
    id: 11,
    image: Hematinic6,
    title: " MULTIKLUB SG",
    cost: 199.00,
    category: "hematinic-neutraceuticals",
  },
  {
    id: 12,
    image: Hematinic7,
    title: " I BETA CAROTINE SG",
    cost: 198.00,
    category: "hematinic-neutraceuticals",
  },
  {
    id: 13,
    image: Hematinic8,
    title: " LYCOCHERRY-LG SG",
    cost: 238.00,
    category: "hematinic-neutraceuticals",
  },
  {
    id: 14,
    image: Syrups1,
    title: "OHMERIL LS",
    cost: 130.00,
    category: "syrups",
  },
  { id: 15, image: Syrups2, title: " OHMEDEX", cost: 120.00, category: "syrups" },
  {
    id: 16,
    image: Syrups3,
    title: " OHMEREX-T",
    cost: 165.00,
    category: "syrups",
  },
  {
    id: 17,
    image: Paediatric1,
    title: "ROHMENTIN DS",
    cost: 67.20,
    category: "paediatric",
  },
  {
    id: 18,
    image: Paediatric2,
    title: " ROHMENTIN DDS",
    cost: 173.00,
    category: "paediatric",
  },
  {
    id: 19,
    image: Paediatric3,
    title: " CEDOX-CV-50 DS",
    cost: 125.00,
    category: "paediatric",
  },
  {
    id: 20,
    image: Paediatric4,
    title: " ROHCEF OF-50 DS",
    cost: 110.00,
    category: "paediatric",
  },
  {
    id: 21,
    image: Paediatric5,
    title: " ROHCEF CV 50 DS",
    cost: 117.00,
    category: "paediatric",
  },
  {
    id: 22,
    image: Paediatric6,
    title: " P CEMOL 250 SUSP",
    cost: 40.20,
    category: "paediatric",
  },
  { id: 23, image: Gastro1, title: "ROHBI DSR", cost: 120.00, category: "gastro" },
  {
    id: 24,
    image: Gastro2,
    title: " ROHPAN DSR",
    cost: 140.00,
    category: "gastro",
  },
  { id: 25, image: Gastro3, title: " ROHBI LS", cost: 185.00, category: "gastro" },
  { id: 26, image: Gastro4, title: " OHME 20", cost: 64.00, category: "gastro" },
  { id: 27, image: Gastro5, title: " OHMILOC", cost: 25.00, category: "gastro" },
  {
    id: 28,
    image: Ointment1,
    title: "ROHMIGEL SUPER",
    cost: 125.00,
    category: "ointment-derma",
  },
  {
    id: 29,
    image: Ointment2,
    title: " OHMNIDINE",
    cost: 325.00,
    category: "ointment-derma",
  },
  {
    id: 30,
    image: Ointment3,
    title: " OHMNIDINE M",
    cost: 90.00,
    category: "ointment-derma",
  },
  {
    id: 31,
    image: Ointment4,
    title: " NEOKLUB-GM",
    cost: 75.00,
    category: "ointment-derma",
  },
  {
    id: 32,
    image: Ointment5,
    title: " LICEGEL",
    cost: 63.50,
    category: "ointment-derma",
  },
  {
    id: 33,
    image: Ointment6,
    title: " OHMSORE",
    cost: 70.00,
    category: "ointment-derma",
  },
  {
    id: 34,
    image: Ointment7,
    title: " DERMIFIVE-KT",
    cost: 95.00,
    category: "ointment-derma",
  },
  {
    id: 35,
    image: Ointment8,
    title: " FOURKLUB",
    cost: 75.00,
    category: "ointment-derma",
  },
  {
    id: 36,
    image: Microbail1,
    title: " ITZ 100",
    cost: 159.00,
    category: "anti-bacterial-microbail",
  },
  {
    id: 37,
    image: Microbail2,
    title: " ITZ 200",
    cost: 265.00,
    category: "anti-bacterial-microbail",
  },
  {
    id: 38,
    image: Microbail3,
    title: " MOHNTI-LC",
    cost: 125.00,
    category: "anti-bacterial-microbail",
  },
  { id: 39, image: Neuro1, title: "TROHMA 50", cost: 56.13, category: "neuro" },
  { id: 40, image: Neuro2, title: " TROHMA 100", cost: 190.00, category: "neuro" },
  {
    id: 41,
    image: Neuro3,
    title: " PROHGESIC-75",
    cost: 160.00,
    category: "neuro",
  },
  {
    id: 42,
    image: Neuro4,
    title: " PROHGESIC-M75",
    cost: 185.00,
    category: "neuro",
  },
  {
    id: 43,
    image: Neuro5,
    title: " PROXIOHM SPAS BLUE",
    cost: 68.00,
    category: "neuro",
  },
  {
    id: 44,
    image: Neuro6,
    title: " PROXIOHM SPAS BROWN",
    cost: 68.00,
    category: "neuro",
  },
  {
    id: 45,
    image: Neuro7,
    title: " PROXIOHM SPAS (144)",
    cost: 68.00,
    category: "neuro",
  },
  {
    id: 46,
    image: Biotics1,
    title: "ROHMENTIN 625 (A/A)",
    cost: 204.84,
    category: "anti-biotics",
  },
  {
    id: 47,
    image: Biotics2,
    title: " ROHMENTIN 625 (SILVER STRIP)",
    cost: 204.84,
    category: "anti-biotics",
  },
  {
    id: 48,
    image: Biotics3,
    title: " FOXCYN",
    cost: 67.00,
    category: "anti-biotics",
  },
  {
    id: 49,
    image: Biotics4,
    title: " CEDOX CV ",
    cost: 349.00,
    category: "anti-biotics",
  },
  {
    id: 50,
    image: Injectibles1,
    title: "OHMICEF 1GM",
    cost: 69.86,
    category: "injectibles",
  },
  {
    id: 51,
    image: Injectibles2,
    title: " OHMICEF-SB ",
    cost: 195.00,
    category: "injectibles",
  },
  {
    id: 52,
    image: Injectibles3,
    title: " ROHPAN-40",
    cost: 56.50,
    category: "injectibles",
  },
  {
    id: 53,
    image: Injectibles4,
    title: "VOHMITAC",
    cost: 13.10,
    category: "injectibles",
  },
];

export default ProductsData;
