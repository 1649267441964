import React, { useState } from 'react'
import "./products.css";
import ProductData from "./ProductsData";
import { Link } from "react-router-dom";
import ImageModal from "../Modal/ImageModal";

const Syrups = () => {
    const syrupsData = ProductData.filter(
        (elem) => elem.category === "syrups"
      );
      const [show,setShow] = useState(false);
      const [data,setData] = useState();
      const openImage = (item)=>{
      setData(item);
      setShow(true);
     }
     const onClose =()=>{
      setShow(false);
     }
  return (
    <>
      <section className="hero__productscategory ">
        <div className="hero__inner container">
          <div className="hero__title">
            <h2>Syrups</h2>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Syrups
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <section className="product__area my-5">
        <div className="container">
          <div className="row">
            {syrupsData.map((item, id) => {
              return (
                <div className="card__body col-md-4 col-sm-12 my-3" key={id}>
                  <div className="card unround__border text-centers" onClick={()=>openImage(item)}>
                    <img
                      src={item.image}
                      className="card-img-top unround__border"
                      alt="..."
                      //   style={{ width: "180px" }}
                    />
                    <div className="card-body">
                      <h5 className="card-title text-center">{item.title} <span className="trade__mark">&#174;</span></h5>
                      <p className="card-text text-center">₹ {item.cost}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <ImageModal show={show} hide ={onClose} data = {data}/>
      </section>
    </>
  )
}

export default Syrups