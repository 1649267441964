import React from "react";
import "./about.css";
import Image1 from "../../assests/about-us-trans.png";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <section className="hero__about ">
        <div className="hero__inner container">
          <div className="hero__title">
            <h2>About</h2>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                About
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="profile__about">
        <div className="container ">
          <div className="row">
            <div className="profile__img col-md-4 text-center my-2">
              <img src={Image1} alt="" width={270} />
            </div>
            <div className="profile__text col-md-8 my-2">
              <h3>Company Profile</h3>
              <div className="custom__div1">
                    <div className="custom__aboutline mb-2"></div>
            </div>
              <p>
              Welcome to OHMS Medicare Pvt. Ltd., a distinguished member of the esteemed Bholenath Enterprises group. Nestled in the heart of Pithampur, Madhya Pradesh, India, OHMS Medicare stands as a beacon of excellence in the pharmaceutical industry. With a legacy spanning three decades, Bholenath Enterprises has been a trusted provider of quality products and services, earning a stellar reputation in the sector.{" "}
              </p>
              {/* <br /> */}
              At OHMS, the pillars upon which its strength rests are:
              <ul>
                <li>Impeccable Quality</li>
                <li>Commitment to Excellence</li>
                <li>Constant Innovation</li>
                {/* <li>Environment Conducive to Growth</li>
                <li>Affordable Medicines</li> */}
              </ul>
            </div>
          </div>
          <div className="profile__outer my-2 ">
            <p>
            At the core of our success lies a well-equipped team operating under the renowned banner of Bholenath Enterprises. Our client roster, including industry giants such as Cipla, Alkem, Lupin, Torrent, Mylon, Ajanta, and Park Pharma, serves as a testament to the unparalleled service and quality consciousness that define our organization. Pithampur, recognized as a pharmaceutical hub in Madhya Pradesh, India, has been our home, where we have thrived and contributed to the industry's growth.
            <br /><br />
            OHMS Medicare Pvt. Ltd. is meticulously managed and committed to delivering top-notch products and services to our valuable clients and business associates. Guided by a management team rich in experience, our company is led by a vision to provide quality healthcare solutions. Our dedicated marketing team, backed by years of industry expertise, is actively expanding our PAN India marketing network, complemented by a committed field force focused on grassroots operations.
            <br /><br />
            As we continue to grow, OHMS Medicare is on a quest to forge strong partnerships with the best business associates across India. We invite you to explore the world of OHMS Medicare, where a legacy of excellence meets a commitment to quality, ensuring a healthier and brighter future for all.
            </p>
          </div>
        </div>
      </section>
      <section className="card__about">
        <div className="container card__flex">
            <div className="left__box col-md-6 col-sm-12">
                <h5>Impeccable Quality</h5>
                <p>Impeccable quality is the hallmark of OHMS Medicare Pvt. Ltd., a distinguished member of the renowned Bholenath Enterprises group. With a heritage of three decades in the pharmaceutical industry, we have consistently upheld a standard of excellence that sets us apart. Our commitment to delivering products and services of the highest quality is reflected in our long-standing relationships with industry leaders such as Cipla, Alkem, Lupin, Torrent, Mylon, Ajanta, and Park Pharma. Operating from the pharmaceutical hub of Pithampur, Madhya Pradesh, India, our well-equipped team, guided by experienced management, is dedicated to providing healthcare solutions that meet and exceed the stringent demands of the industry.</p>
            </div>
            <div className="right__box col-md-6 col-sm-12">
                <h5>Environment Conducive To Growth</h5>
                <p>Growth and development at OHMS aim at expanding its global footprint. The workforce is an integral part of our success story and is treated as such. Our personnel’s courage, capacity, confidence, perseverance, and unrelenting determination help us face internal and external challenges. Mutual respect, an encouraging environment, and ample development opportunities help us to bring out the best in the workforce. Growth and development at OHMS Medicare is aimed at expanding its global footprint. The workforce is an integral part of our success story and is treated as such.</p>
            </div>
        </div>
      </section>
      <section className="values__about">
        <div className="container values__flex">
            <div className="first__value my-3">
                <h5>Commitment To Excellence</h5>
                <p>Continuous effort is constantly underway to improve our products in every possible manner. If there is scope for improvement, we will achieve it. Our highly qualified and experienced scientists work continuously to develop new products.</p>
            </div>
            <div className="second__value my-3">
                <h5>Constant Innovation</h5>
                <p>Through research and development activities, our team is always committed to finding better alternatives to more expensive and less feasible products which can be mass produced for the benefit of mankind. This aims to strengthen the foundation and build a formidable reputation for the company and the entire pharmaceutical industry.</p>
            </div>
            <div className="third__value my-3">
                <h5>Affordable Medicines</h5>
                <p>70% of India and about two dozen countries benefit from our affordable medicines. Making sure that the best quality medicines are available to the masses at the most affordable prices gives us great satisfaction. Through our constant innovation practices, we can make this possible. Not just treating diseases and sicknesses, we aim for prophylactic treatments that improve the quality of life.</p>
            </div>
        </div>
      </section>
    </>
  );
};

export default About;
