import React from 'react'
import Image1 from '../../assests/gear-six.svg'
import Image2 from '../../assests/pharmacy.svg'
import Image3 from '../../assests/surgery-tools.svg'


const Usp = () => {
  return (
    <>
       <section className="home__usp ">
        <div className="container">
            <h3 className="text-center">Who we serve</h3>
            <div className="custom__div">
            <div className="custom__uspline"></div>
          </div>
            <div className="usp__card row mt-5">                
                
                <div className="icon__card col-md-4 text-center">
                    <div className="icon__img mb-2">
                        <img src={Image1} alt="" width={70} />
                    </div>
                    <div className="icon__text">
                        <h5>Global Quality Standards</h5>
                        <p className='usp__text'>Your health deserves nothing but the best. We adhere to the highest international quality standards in distribution of our pharmaceuticals.</p>
                    </div>
                </div>
                <div className="icon__card col-md-4 text-center icon__divider">
                    <div className="icon__img mb-2">
                        <img src={Image2} alt="" width={70}/>
                    </div>
                    <div className="icon__text">
                        <h5>Patient-Centric Care</h5>
                        <p className='usp__text'>We go beyond merely providing medications; we strive to create an empathetic and supportive ecosystem where patients feel understood, cared for their health journey.</p>
                    </div>
                </div>
                <div className="icon__card col-md-4 text-center">
                    <div className="icon__img mb-2">
                        <img src={Image3} alt="" width={70}/>
                    </div>
                    <div className="icon__text">
                        <h5>Expert Pharmaceuticals Team</h5>
                        <p className='usp__text'>With a diverse range of expertise, they contribute to the development of groundbreaking pharmaceuticals and ensure that our clients receive the best.</p>
                    </div>
                </div>
            </div>
        </div>
        </section> 
    </>
  )
}

export default Usp