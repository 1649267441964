import React from "react";
import "./contact.css";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <section className="hero__contact ">
        <div className="hero__inner container">
          <div className="hero__title">
            <h2>Contact</h2>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Contact
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="contact__section my-5">
        <div className="container">
          <div className="row">
            <div className="concard__container col-xs-12 col-sm-12 col-md-12">
              <div className="custom__concard my-2">
                <span className="icon-location-pin mb-4"></span>
                <h6>Shop No. 109, 110 Ward No. 7, Ram Ratan Patel Nagar, Pithampur 454775</h6>
              </div>
              <div className="custom__concard my-2">
                <span className="icon-phone mb-4"></span>
                <h6>+91 9753513177</h6>
              </div>
              <div className="custom__concard my-2">
                <span className="icon-envelope mb-4"></span>
                <h6>ohmsmedicare@gmail.com</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
