import React from 'react'
// import Hero from './Hero'
import Textcontainer from './Textcontainer'
import './home.css'
import Card from './Card'
import FilterData from './FilterData'
import Usp from './Usp'
import HeroCarousel from './HeroCarousel'

const Home = () => {
  return (
    <>
        {/* <Hero/> */}
        <HeroCarousel/>
        <Textcontainer/>
        <Card/>
        <FilterData/>
        <Usp/>
    </>
  )
}

export default Home