import React from "react";

const Bottombar = () => {
  return (
    <>
      <div className="footer__bottom text-center ">
        <span>Copyright © 2023 Ohms Medicare. All Rights Reserved.</span>
      </div>
    </>
  );
};

export default Bottombar;
