import React from "react";
import "./products.css";
import CategoryData from "./CategoryData";
import { Link } from "react-router-dom";

const ProductsCategory = () => {
  return (
    <>
      <section className="hero__productscategory">
        <div className="hero__inner container">
          <div className="hero__title">
            <h2>Products</h2>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Products
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="category__card my-5">
        <div className="container">
          <div className="row align-items-md-stretch">
            {CategoryData.map((items)=>{
                return (
                    <div className="col-md-4 my-2" key={items.id}>
                    <div className="h-100 p-5 bg-body-tertiary border rounded-3 category__bg">
                      <h2>{items.title}</h2>
                      <p>
                        {items.desc}
                      </p>
                      <Link to={items.url} className="btn btn-outline-secondary" type="button">
                        View Products
                      </Link>
                    </div>
                  </div>
                )
            })}
            

          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsCategory;
