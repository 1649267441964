import React from 'react'

const Textcontainer = () => {
  return (
    <>
        <section className="custom__container my-5">
            <div className="container">
                <h3 className='text-center'>Welcome to <span className='red__color'>OHMS</span> <span className="darkblue__color">Medicare</span></h3>
                <div className="custom__div">
            <div className="custom__textline"></div>
          </div>
                {/* <hr className="custom__line" /> */}
                <p className='text-center my-4'>OHMS Medicare is a leading pharma company providing high quality medicines for healthier world. We deal in high quality branded generic drugs and have Pan India sales and distribution network. <br />
                With the best of our services, we have already come a long way to become the market leader in pharma sector. All this has been possible because of a team of well informed, highly competent, promptly executing and competetive professionals with vast experience in pharmaceuticals. This makes OHMS Medicare a reliable and trustworthy source for and extensive range of pharmaceutical products to meet the various need of the pharmaceutical industry. <br />
                We firmly believe in ethical business practices and maintain full transparency in all our activities. Our believe lies in consistent efforts to provide safe, effective and reliable pharmaceutical products to our customers. 
                </p>
            </div>
        </section>
    </>
  )
}

export default Textcontainer