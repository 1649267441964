import React, { useState } from "react";
import Data from "./Data";
import ImageModal from "../Modal/ImageModal";
// import Image from "../../assests/slider_1.png";

const FilterData = () => {

  const [item, setItem] = useState(Data.filter((elem) => elem.category === "paediatric"));
  const [active, setActive] = useState("paediatric")
  
  const filterItem = (category) => {
    const newItems = Data.filter((elem) => {
      return elem.category === category;
    });
    setItem(newItems);
    setActive(category)
  };

  const [show,setShow] = useState(false);
      const [data,setData] = useState();
      const openImage = (item)=>{
      setData(item);
      setShow(true);
     }
     const onClose =()=>{
      setShow(false);
     }
  return (
    <>
      <section className="filter__section">
        <div className="container">
          <h3 className="text-center">View by category</h3>
          <div className="custom__div">
            <div className="custom__line"></div>
          </div>
          <div className="category my-5">
            <div
              className={active==="paediatric"?"category__name active__state":"category__name"}
              onClick={() => filterItem("paediatric")}
            >
              Paediatric Range
            </div>
            <div
              className={active==="analgesics"?"category__name active__state":"category__name"}
              onClick={() => filterItem("analgesics")}
            >
              Analgesics
            </div>
            {/* <div
              className={active==="antiallergic"?"category__name active__state":"category__name"}
              onClick={() => filterItem("antiallergic")}
            >
              Hematinic & Neutraceuticals
            </div> */}
            <div
              className={active==="neuro"?"category__name active__state":"category__name"}
              onClick={() => filterItem("neuro")}
            >
              Neuro 
            </div>
            <div
              className={active==="gastro"?"category__name active__state":"category__name"}
              onClick={() => filterItem("gastro")}
            >
              Gastroenterology
            </div>
          </div>
          <div className="row">
            {item.map((elem,id) => {
              return (
                <div className="card__body col-md-4 col-sm-12 my-3" key={id}>
                  <div className="card unround__border text-centers" onClick={()=>openImage(elem)}>
                    <img
                      src={elem.image}
                      className="card-img-top unround__border"
                      alt="..."
                      //   style={{ width: "180px" }}
                    />
                    <div className="card-body">
                      <h5 className="card-title text-center">{elem.title} <span className="trade__mark">&#174;</span></h5>
                      <p className="card-text text-center">
                      ₹ {elem.cost}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <ImageModal show={show} hide ={onClose} data = {data}/>
      </section>
    </>
  );
};

export default FilterData;
