// Paediatric
import Paediatric1 from "../../assests/products/rohmentin-ds.jpg";
import Paediatric2 from "../../assests/products/rohmentin-dds.jpg";
import Paediatric3 from "../../assests/products/cedox-cv-50.jpg";
import Paediatric4 from "../../assests/products/rohcef-of-50.png";
import Paediatric5 from "../../assests/products/rohcef-cv-50.png";
import Paediatric6 from "../../assests/products/p-cemol.png";

// Analgesics Images
import Analgesics1 from "../../assests/products/ap-klub-sp-10.png";
import Analgesics2 from "../../assests/products/ap-klub.jpg";
import Analgesics3 from "../../assests/products/rohnim-plus.png";
import Analgesics4 from "../../assests/products/ohmenac-plus.png";
import Analgesics5 from "../../assests/products/ap-klub-sp.jpg";

// Gastro Images
import Gastro1 from "../../assests/products/rohbi-dsr.png";
import Gastro2 from "../../assests/products/rohpan-dsr.png";
import Gastro3 from "../../assests/products/rohbi-ls.png";
import Gastro4 from "../../assests/products/ohme-20.png";
import Gastro5 from "../../assests/slider_1.png";

// Neuro
import Neuro3 from "../../assests/products/prohgesic-75.png";
import Neuro4 from "../../assests/products/prohgesic-m75.png";
import Neuro5 from "../../assests/products/proxiohm-spas.png";
import Neuro6 from "../../assests/products/proxiohm-spas-brown.png";
import Neuro7 from "../../assests/products/proxiohm-spas-144.png";

const Menu = [
  {
    id: 1,
    image: Analgesics1,
    title: "AP-KLUB SP 10",
    cost: 120.0,
    category: "analgesics",
  },
  {
    id: 2,
    image: Analgesics2,
    title: "AP-KLUB",
    cost: 77.5,
    category: "analgesics",
  },
  {
    id: 3,
    image: Analgesics3,
    title: " ROHNIM PLUS",
    cost: 50.0,
    category: "analgesics",
  },
  {
    id: 4,
    image: Analgesics4,
    title: " OHMENAC-PLUS",
    cost: 36.0,
    category: "analgesics",
  },
  {
    id: 5,
    image: Analgesics5,
    title: " AP-KLUB-SP",
    cost: 125.0,
    category: "analgesics",
  },
  {
    id: 6,
    image: Gastro1,
    title: "ROHBI DSR",
    cost: 120.0,
    category: "gastro",
  },
  {
    id: 7,
    image: Gastro2,
    title: " ROHPAN DSR",
    cost: 140.0,
    category: "gastro",
  },
  {
    id: 8,
    image: Gastro3,
    title: " ROHBI LS",
    cost: 185.0,
    category: "gastro",
  },
  { id: 9, image: Gastro4, title: " OHME 20", cost: 64.0, category: "gastro" },
  { id: 10, image: Gastro5, title: " OHMILOC", cost: 25.0, category: "gastro" },
  {
    id: 11,
    image: Neuro3,
    title: " PROHGESIC-75",
    cost: 160.0,
    category: "neuro",
  },
  {
    id: 12,
    image: Neuro4,
    title: " PROHGESIC-M75",
    cost: 185.0,
    category: "neuro",
  },
  {
    id: 13,
    image: Neuro5,
    title: " PROXIOHM SPAS BLUE",
    cost: 68.0,
    category: "neuro",
  },
  {
    id: 14,
    image: Neuro6,
    title: " PROXIOHM SPAS BROWN",
    cost: 68.0,
    category: "neuro",
  },
  {
    id: 15,
    image: Neuro7,
    title: " PROXIOHM SPAS (144)",
    cost: 68.0,
    category: "neuro",
  },
  {
    id: 16,
    image: Paediatric1,
    title: "ROHMENTIN DS",
    cost: 67.2,
    category: "paediatric",
  },
  {
    id: 17,
    image: Paediatric2,
    title: " ROHMENTIN DDS",
    cost: 173.0,
    category: "paediatric",
  },
  {
    id: 18,
    image: Paediatric3,
    title: " CEDOX-CV-50 DS",
    cost: 125.0,
    category: "paediatric",
  },
  {
    id: 19,
    image: Paediatric4,
    title: " ROHCEF OF-50 DS",
    cost: 110.0,
    category: "paediatric",
  },
  {
    id: 20,
    image: Paediatric5,
    title: " ROHCEF CV 50 DS",
    cost: 117.0,
    category: "paediatric",
  },
  {
    id: 21,
    image: Paediatric6,
    title: " P CEMOL 250 SUSP",
    cost: 40.2,
    category: "paediatric",
  },
];

export default Menu;
