import React from "react";
import Logo1 from "../../assests/logo2.png";
import { Link } from "react-router-dom";

const Nav = () => {
  // const [toggle, showToggle] = useState(false);
  return (
    <>
      {/* <div className="container__nav">
        <div className="nav__logo">
          <img src={Logo1} alt="" width={220} />
        </div>
        <nav className="nav__menu">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
      </div>

      <aside className={toggle ? "aside show-menu" : "aside"}>
        <Link to="/" className="aside__logo">
          <img src={Logo1} alt="" srcSet="" width={220} />
        </Link>

        <nav className="aside__nav">
          <div className="">
            <ul className="aside__list">
              <Link to="/">
                <li className="aside__item">Home</li>
              </Link>

              <Link to="/products">
                <li className="aside__item">Products</li>
              </Link>

              <Link to="/about">
                <li className="aside__item">About</li>
              </Link>

              <Link to="/contact">
                <li className="aside__item">Contact</li>
              </Link>
            </ul>
          </div>
        </nav>
      </aside>

      <div
        className={toggle ? "nav__toggle nav__toggle-open" : "nav__toggle"}
        onClick={() => showToggle(!toggle)}
      >
        {!toggle ? (
          <i className="icon-menu"></i>
        ) : (
          <i className="icon-close"></i>
        )}
      </div> */}

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark nav__custom">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={Logo1} alt="Your Logo"  width={220} />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link nav__link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link nav__link" to="/products">
                  Products
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link nav__link" to="/management-desk">
                  Management Desk
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link nav__link" to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link nav__link" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src={Logo1} alt="" width={220} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Products
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" aria-disabled="true">
                  Disabled
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
    </>
  );
};

export default Nav;
