import React from 'react';
import './management.css';
import Mgt1 from '../../assests/mgt/ramesh-tiwari.png'
import Mgt2 from '../../assests/mgt/raj-kishor.png'
// import Mgt3 from '../../assests/mgt/kk-tripathi.png'
import Mgt4 from '../../assests/mgt/adarsh-pandey.png'
import Mgt5 from '../../assests/mgt/bijender-tiwari.png'





const ManagementDesk = () => {
  return (
    <>
    <section className="hero__about ">
        <div className="hero__inner container">
          <div className="hero__title">
            <h2>Management Desk</h2>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Management Desk
              </li>
            </ol>
          </nav>
        </div>
    </section>
    <section className="management__section desk-view">
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-3 text-center">
                    <img src={Mgt1} alt="" />
                    <div className='mt-2'><h5>Ramesh Tiwari</h5></div>
                    <div><h6>(Managing Director)</h6></div>
                </div>
                <div className="col-md-9 d-flex align-items-center mgt__txt">
                    <p>
                        <span className="mgt-abt">- CA and Director-Bholenath Enterprises</span> <br />
                        Health is more important than anything else, we, here in OHMS are determined to make people healthy.</p>
                </div>
            </div>
            <div className="row my-4">
                
                <div className="col-md-9 d-flex align-items-center mgt__txt">
                    <p>We are here to serve, to make a healthy & better society through the products of OHMS & create a path of the Healthy world. Work hard grow together, update & upgrade timely.</p>
                </div>
                <div className="col-md-3 text-center">
                    <img src={Mgt2} alt="" />
                    <div className='mt-2'><h5>Raj Kishor Pandey</h5></div>
                    <div><h6>(GM-Marketing and Purchase)</h6></div>
                </div>
            </div>
            <div className="row my-4">
                <div className="col-md-3 text-center">
                    <img src={Mgt5} alt="" />
                    <div className='mt-2'><h5>Bijender Tiwari</h5></div>
                    <div><h6>(Director)</h6></div>
                </div>
                <div className="col-md-9 d-flex align-items-center mgt__txt">
                    <p>
                        <span className="mgt-abt">- Technical Advisor and Director-Bholenath Enterprises</span> <br />
                        Our focus on continuous improvement and operational excellence enables us to deliver efficient and effective healthcare solutions.</p>
                </div>
            </div>
            <div className="row my-4">
                <div className="col-md-9 d-flex align-items-center mgt__txt">
                    <p>
                        <span className="mgt-abt">- MSc. Chemistry</span><br />
                        Empowered by conviction, forging a healthier future. Our commitment to excellence shapes a path towards a robust tomorrow. Discover the vitality of innovation with OHMS Medicare, where every conviction propels us towards a healthier, brighter world.</p>
                </div>
                <div className="col-md-3 text-center">
                    <img src={Mgt4} alt="" />
                    <div className='mt-2'><h5>Adarsh</h5></div>
                    <div><h6>(Director)</h6></div>
                </div>
            </div>
            
        </div>
    </section>
    <section className="management__section mob-view">
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-3 text-center">
                    <img src={Mgt1} alt="" />
                    <div className='mt-2'><h5>Ramesh Tiwari</h5></div>
                    <div><h6>(Managing Director)</h6></div>
                </div>
                <div className="col-md-9 d-flex align-items-center mgt__txt">
                    <p>
                    <span className="mgt-abt">- CA and Director-Bholenath Enterprises</span> <br />
                        Health is more important than anything else, we, here in OHMS are determined to make people healthy.</p>
                </div>
            </div>
            <div className="row my-4">
                
                <div className="col-md-3 text-center">
                    <img src={Mgt2} alt="" />
                    <div className='mt-2'><h5>Raj Kishor Pandey</h5></div>
                    <div><h6>(GM-Marketing and Purchase)</h6></div>
                </div>
                <div className="col-md-9 d-flex align-items-center mgt__txt">
                    <p>We are here to serve for make a healthy & better society through the products of OHMS & create a path of the Healthy world. Work hard grow together, update & upgrade timely.</p>
                </div>
            </div>
            <div className="row my-4">
                <div className="col-md-3 text-center">
                    <img src={Mgt5} alt="" />
                    <div className='mt-2'><h5>Bijender Tiwari</h5></div>
                    <div><h6>(Director)</h6></div>
                </div>
                <div className="col-md-9 d-flex align-items-center mgt__txt">
                    <p>
                    <span className="mgt-abt">- Technical Advisor and Director-Bholenath Enterprises</span> <br />
                        Our focus on continuous improvement and operational excellence enables us to deliver efficient and effective healthcare solutions.</p>
                </div>
            </div>
            <div className="row my-4">
                <div className="col-md-3 text-center">
                    <img src={Mgt4} alt="" />
                    <div className='mt-2'><h5>Adarsh</h5></div>
                    <div><h6>(Director)</h6></div>
                </div>
                <div className="col-md-9 d-flex align-items-center mgt__txt">
                    <p>
                    <span className="mgt-abt">- MSc. Chemistry</span><br />
                        Empowered by conviction, forging a healthier future. Our commitment to excellence shapes a path towards a robust tomorrow. Discover the vitality of innovation with OHMS Medicare, where every conviction propels us towards a healthier, brighter world.</p>
                </div>
            </div>
            
        </div>
    </section>
    </>
  )
}

export default ManagementDesk