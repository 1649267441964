import React from 'react'

const Topbar = () => {
  return (
    <div>
        <div className="container__topbar">
          <div className="topbar__left"><span><span className="icon-phone"></span> Phone: +91 9753513177</span> <span><span className="icon-envelope"></span> Email: ohmsmedicare@gmail.com</span></div>
          <div className="topbar__right">
            <span className="icon-social-youtube"></span> <span className="icon-social-instagram"></span> <span className="icon-social-twitter"></span>
          </div>
        </div>
    </div>
  )
}

export default Topbar