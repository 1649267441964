import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const ImageModal = (props) => {
    console.log(props.data)
    const {show,hide,data} = props
  return (
    <div>
         <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>{data?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <img
                      src={data?.image}
                      className="card-img-top unround__border"
                      alt="..."
                      //   style={{ width: "180px" }}
                    />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ImageModal