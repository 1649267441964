import React from "react";
import Logo2 from "../../assests/footer_logo.png";
import "./footer.css";
import Bottombar from "./Bottombar";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3 mb-4">
              <img src={Logo2} alt="" width={120} />
              <p className="my-2">
                Serving health together.
              </p>
            </div>

            <div className="col-md-3 mb-4">
              <h5>Important Links</h5>
              <ul className="list-unstyled footer__menu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3 mb-4">
              <h5>Quick Links</h5>
              <ul className="list-unstyled footer__menu">
                <li>
                  <Link to="/products">Products</Link>
                </li>
                <li>
                  <Link to="/management-desk">Mangement's Desk</Link>
                </li>
                {/* <li><a href="#">Quick Link 3</a></li> */}
              </ul>
            </div>

            <div className="col-md-3 mb-4">
              <h5>Contact</h5>
              <ul className="list-unstyled">
                <li>
                  <i className="icon-envelope"></i> ohmsmedicare@gmail.com
                </li>
                <li>
                  <i className="icon-phone"></i> +91 9753513177
                </li>
              </ul>

              <div className="social-icons mt-4">
                <a href="#" className="text-light">
                  <i className="icon-social-facebook"></i>
                </a>
                <a href="#" className="text-light">
                  <i className="icon-social-twitter mx-3"></i>
                </a>
                <a href="#" className="text-light">
                  <i className="icon-social-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Bottombar />
      </footer>
    </>
  );
};

export default Footer;
