import React from 'react'
import WhatappImg from '../assests/WhatsApp.svg'

const WhatsApp = () => {

  const handleWhatsAppClick = () => {
    // Replace '123123344' with the actual WhatsApp number
    const whatsappNumber = '919753513177';
    const whatsappLink = `https://wa.me/${whatsappNumber}`;
    
    // Open the WhatsApp link in a new window
    window.open(whatsappLink, '_blank');
  };
  return  (
    <div
      style={{
        zIndex:999999,
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        padding: '15px',    
        background: '#25D366',
        color: '#fff',
        borderRadius: '50%',
        cursor: 'pointer',
      }}
      onClick={handleWhatsAppClick}
      rel="noreferrer"
    >
      <img src={WhatappImg} alt="" width={35} />
    </div>
  );
};

export default WhatsApp