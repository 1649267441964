import Carousel from "react-bootstrap/Carousel";
import CImage1 from "../../assests/Banner1.png";
import CImage2 from "../../assests/Banner2.png";
import CImage3 from "../../assests/Banner3.png";
import CImage4 from "../../assests/Banner4.png";
import CImage5 from "../../assests/Banner5.png";


function HeroCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <img style={{ width: "100%" }} alt="900x500" src={CImage1} />
        {/* <Carousel.Caption>
      <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img style={{ width: "100%" }} alt="900x500" src={CImage2} />
        {/* <Carousel.Caption>
      <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img style={{ width: "100%" }} alt="900x500" src={CImage3} />
        {/* <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img style={{ width: "100%" }} alt="900x500" src={CImage4} />
        {/* <Carousel.Caption>
      <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img style={{ width: "100%" }} alt="900x500" src={CImage5} />
        {/* <Carousel.Caption>
      <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
  );
}

export default HeroCarousel;
