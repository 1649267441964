import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import {
  HashRouter as Router,
  Routes,
  Route,
  // Link
} from "react-router-dom";
import ProductsCategory from "./components/products/ProductsCategory";
import Analgesics from "./components/products/Analgesics";
import Gastro from "./components/products/Gastro";
import Syrups from "./components/products/Syrups";
import AntiBacterialMicrobial from "./components/products/AntiBacterialMicrobial";
import HematinicNeutraceuticals from "./components/products/HematinicNeutraceuticals";
import OintmentDerma from "./components/products/OintmentDerma";
import Paediatric from "./components/products/Paediatric";
import Neuro from "./components/products/Neuro";
import AntiBiotics from "./components/products/AntiBiotics";
import Injectibles from "./components/products/Injectibles";
import ManagementDesk from "./components/management/ManagementDesk";
import ScrollToTop from "./components/ScrollToTop";
import WhatsApp from "./components/WhatsApp";
// import PopUpModal from "./components/PopUpModal";



function App() {
  return (
    <>
    <Router>
      <Navbar />
      <ScrollToTop/>
      <Routes>
      <Route exact path='/' element={<Home/>}/>
      <Route exact path='/about' element={<About/>}/>
      <Route exact path='/contact' element={<Contact/>}/>
      <Route exact path='/products' element={<ProductsCategory/>}/>
      <Route exact path='/management-desk' element={<ManagementDesk/>}/>
      <Route exact path='/products/analgesics' element={<Analgesics/>}/>
      <Route exact path='/products/gastro' element={<Gastro/>}/>
      <Route exact path='/products/syrups' element={<Syrups/>}/>
      <Route exact path='/products/anti-bacterial-microbial' element={<AntiBacterialMicrobial/>}/>
      <Route exact path='/products/hematinic-neutraceuticals' element={<HematinicNeutraceuticals/>}/>
      <Route exact path='/products/ointment-derma' element={<OintmentDerma/>}/>
      <Route exact path='/products/paediatric' element={<Paediatric/>}/>
      <Route exact path='/products/neuro' element={<Neuro/>}/>
      <Route exact path='/products/anti-biotics' element={<AntiBiotics/>}/>
      <Route exact path='/products/injectibles' element={<Injectibles/>}/>
      </Routes>
      <Footer/>
      {/* <PopUpModal/> */}
      <WhatsApp/>
    </Router>
    </>
  );
}

export default App;
