import React from "react";
import Card1 from "../../assests/card-1.png";
import Card2 from "../../assests/card-2.png";
import Card3 from "../../assests/card-3.png";
import Card4 from "../../assests/card-4.png";
import Pdf1 from "../../assests/26.jpg"
import Pdf2 from "../../assests/55.jpg"
import Pdf3 from "../../assests/5.jpg"
import Pdf4 from "../../assests/10.jpg"




const Card = () => {
  return (
    <>
      <section className="card__section">
        <div className="card__bg">
          <div className="container ">
            <div className="card__container row my-2 mx-2">
              <div className="outer__box col mx-4 my-3 bg-small1">
                <div className="inner__text">
                  <h5>ROHMIGEL SUPER <span className="trade__mark">&#174;</span></h5>
                  <a href={Pdf2} target="_blank" rel="noreferrer" className="card__btn btn-sm btn-primary btn mt-4">
                    Know More
                  </a>
                </div>
                <div className="inner__img">
                  <img src={Card1} alt="" width={150} />
                </div>
              </div>

              <div
                className="outer__box col mx-4 my-3 bg-small2"
              >
                <div className="inner__text">
                  <h5>AP-KLUB <span className="trade__mark">&#174;</span></h5>
                  <a href={Pdf3} target="_blank" rel="noreferrer" className="card__btn btn-sm btn-primary btn mt-4">
                    Know More
                  </a>
                </div>
                <div className="inner__img">
                  <img src={Card2} alt="" width={150} />
                </div>
              </div>
            </div>

            <div className="card__container row my-2 mx-2">
              <div
                className="outer__box col mx-4 my-3 bg-small3"
              >
                <div className="inner__text">
                  <h5>OMENERVE <span className="trade__mark">&#174;</span></h5>
                  <a href={Pdf1} target="_blank" rel="noreferrer" className="card__btn btn-sm btn-primary btn mt-4">
                    Know More
                  </a>
                </div>
                <div className="inner__img">
                  <img src={Card3} alt="" width={150} />
                </div>
              </div>

              <div className="outer__box col mx-4 my-3 bg-small4">
                <div className="inner__text">
                  <h5>OHMENAC PLUS <span className="trade__mark">&#174;</span></h5>
                  <a href={Pdf4} target="_blank" rel="noreferrer" className="card__btn btn-sm btn-primary btn mt-4">
                    Know More
                  </a>
                </div>
                <div className="inner__img">
                  <img src={Card4} alt="" width={150} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Card;
