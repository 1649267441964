import React from 'react'
import Topbar from './Topbar'
import './navbar.css'
import Nav from './Nav'

const Navbar = () => {
  return (
    <div>
        <Topbar/>
        <Nav/>
    </div>
  )
}

export default Navbar