import Image from "../../assests/slider_1.png";

const CategoryData = [
  {
    id: 1,
    image: Image,
    title: "Analgesics",
    desc: "Treatment of mild and severe pain.",
    url: "analgesics",
  },
  {
    id: 2,
    image: Image,
    title: "Gastro",
    desc: "Drugs related to cure of gastric issues.",
    url: "gastro",
  },
  {
    id: 3,
    image: Image,
    title: "Syrups",
    desc: "Addressing various health needs for a healthier you.",
    url: "syrups",
  },
  {
    id: 4,
    image: Image,
    title: "Anti bacterial/ Microbial",
    desc: "Combat infections and bolster your immune defenses with top-tier solution.",
    url: "anti-bacterial-microbial",
  },
  {
    id: 5,
    image: Image,
    title: "Hematinic & Neutraceuticals",
    desc: "Drug formulation for supplementing of blood and body nutritional needs.",
    url: "hematinic-neutraceuticals",
  },
  {
    id: 6,
    image: Image,
    title: "Ointment & Derma",
    desc: "Drug formulation in form of cream for the treatment of skin infections.",
    url: "ointment-derma",
  },
  {
    id: 7,
    image: Image,
    title: "Paediatric",
    desc: "Medicine line related to treatment of diseases related to children.",
    url: "paediatric",
  },
  {
    id: 8,
    image: Image,
    title: "Neuro",
    desc: "Designed to support cognitive health and enhance overall neurological well-being.",
    url: "neuro",
  },
  {
    id: 9,
    image: Image,
    title: "Anti Biotics",
    desc: "Combat bacterial infections, restoring health and well-being with precision and care.",
    url: "anti-biotics",
  },
  {
    id: 10,
    image: Image,
    title: "Injectibles",
    desc: "This injectable medication contains adrenaline, which reverses the effects of a serious allergic reaction.",
    url: "injectibles",
  },
];

export default CategoryData;
